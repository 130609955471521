// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    Elements,
} from '@stripe/react-stripe-js'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
} from 'react-router-dom'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
} from 'data'

// pages
import {
    EditBlockSite,
    ButtonHelper810Site,
} from 'pages'

// serializers
import {
    MainStyleSerializer,
    PortfolioPageContentListSiteSerializer,
    Template810SiteSerializer,
} from 'serializers/site'

// services
import {
    getStylesNew,
    parseQuerySite,
} from 'services'

// props
type TemplateBlock810SiteProps = {
    blockId: string
    content: Template810SiteSerializer | undefined
    isEditHovered: boolean
    isInComponent?: boolean
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    parentArray?: number[]
    parentStyles?: any
    styles: MainStyleSerializer
    stylesEdit: MainStyleSerializer | undefined
}

// main
export const TemplateBlock810Site: React.FC<TemplateBlock810SiteProps> = React.memo(({
    blockId,
    content,
    isEditHovered,
    isInComponent,
    mainParentId,
    object,
    parentArray,
    parentStyles,
    styles,
    stylesEdit,
}) => {

    const dispatch = useDispatch()
    const location = useLocation()
    const reduxCacheSiteportfoliocurrencyiso_code = useSelector((state: defaultReduxState) => state.reduxCacheSite.portfolio?.currency?.iso_code)
    const reduxCacheSiteportfolioid = useSelector((state: defaultReduxState) => state.reduxCacheSite.portfolio?.id)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const deviceType = reduxModalSite.deviceType

    const [amount, setAmount] = useState<number>(0)
    const [amountError, setAmountError] = useState<string>()
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState<string>()
    const [isRecurent, setIsRecurent] = useState(false)
    const [stylesNew, setStylesNew] = useState<{
        buttonBackground: string | undefined,
        buttonBorderColor: string | undefined,
        buttonHoverBackground: string | undefined,
        buttonHoverBorderColor: string | undefined,
        buttonHoverTextColor: string | undefined,
        buttonTextColor: string | undefined,
        colorMode: string | undefined,
    } | undefined>(applyStyles())
    const [buttonStyles, setButtonStyles] = useState<any>({
        background: stylesNew?.buttonBackground,
        borderColor: stylesNew?.buttonBorderColor,
        color: stylesNew?.buttonTextColor,
    })

    useEffect(() => {
        const tempStyles = applyStyles()
        setStylesNew(tempStyles)
        setButtonStyles({
            background: applyStyles()?.buttonBackground,
            borderColor: applyStyles()?.buttonBorderColor,
            color: applyStyles()?.buttonTextColor,
        })
    }, [
        object.id,
        reduxModalSite,
        styles,
        stylesEdit,
    ])

    function applyStyles() {
        try {
            const aStyles = getStylesNew(reduxModalSite, styles, stylesEdit)
            return {
                buttonBackground: aStyles?.buttonBackground,
                buttonBorderColor: aStyles?.buttonBorderColor,
                buttonHoverBackground: aStyles?.buttonHoverBackground,
                buttonHoverBorderColor: aStyles?.buttonHoverBorderColor,
                buttonHoverTextColor: aStyles?.buttonHoverTextColor,
                buttonTextColor: aStyles?.buttonTextColor,
                colorMode: aStyles?.colorMode,
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock810Site',
                'applyStyles',
            ))
        }
    }

    function handleAmountChange(value: any) {
        try {
            setAmount(value)
            setAmountError('')
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock810Site',
                'handleAmountChange',
            ))
        }
    }

    function handleEmailChange(event: any) {
        try {
            setEmail(event.target.value)
            setEmailError('')
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock810Site',
                'handleEmailChange',
            ))
        }
    }

    function onHover(direction: string) {
        try {
            if (direction === 'over') {
                if (stylesNew) {
                    const newnewStyle: any = {}
                    if (stylesNew.buttonHoverBackground) newnewStyle.background = stylesNew.buttonHoverBackground
                    if (stylesNew.buttonHoverBorderColor) newnewStyle.borderColor = stylesNew.buttonHoverBorderColor
                    if (stylesNew.buttonHoverTextColor) newnewStyle.color = stylesNew.buttonHoverTextColor
                    setButtonStyles(newnewStyle)
                } else {
                    setButtonStyles(undefined)
                }
            } else {
                if (stylesNew) {
                    const newnewStyle: any = {}
                    newnewStyle.background = stylesNew.buttonBackground
                    newnewStyle.borderColor = stylesNew.buttonBorderColor
                    newnewStyle.color = stylesNew.buttonTextColor
                    setButtonStyles(newnewStyle)
                } else {
                    setButtonStyles(undefined)
                }
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock810Site',
                'onHover',
            ))
        }
    }

    if (reduxCacheSiteportfolioid === 538) {
        return (
            <div
                id={blockId}
                className={`template-block-810${isEditHovered ? ' is-edit-hovered' : ''} ${stylesNew?.colorMode || 'dark'} ${deviceType}`}
            >
                {parseQuerySite(location.search).success
                    ? (
                        <p className={`t-810-sucess-message ${deviceType}`}>{content?.success_message || reduxText[8519]}</p>
                    ) : (
                        <React.Fragment>
                            <div className={`t-810-block1 ${deviceType}`}>
                                <div className={`t-810-block1-period ${deviceType}`}>
                                    <span
                                        className={`t-810-period-choice${!isRecurent ? ' active' : ''} ${deviceType}`}
                                        onClick={() => setIsRecurent(!isRecurent)}
                                    >
                                        Ponctuel
                                    </span>
                                    <span
                                        className={`t-810-period-choice${isRecurent ? ' active' : ''} ${deviceType}`}
                                        onClick={() => setIsRecurent(!isRecurent)}
                                    >
                                        Mensuel
                                    </span>
                                </div>
                                <div className={`t-810-block1-amount-choices ${deviceType}`}>
                                    <button
                                        className={`t-810-block1-button${(amount === 45) ? ' active' : ''} ${deviceType}`}
                                        // disabled={isLoading}
                                        onClick={() => handleAmountChange(45)}
                                        // onMouseLeave={() => onHover('leave')}
                                        // onMouseOver={() => onHover('over')}
                                        // style={buttonStyles}
                                        type='button'
                                    >
                                        45€
                                    </button>
                                    <button
                                        className={`t-810-block1-button${(amount === 80) ? ' active' : ''} ${deviceType}`}
                                        // disabled={isLoading}
                                        onClick={() => handleAmountChange(80)}
                                        // onMouseLeave={() => onHover('leave')}
                                        // onMouseOver={() => onHover('over')}
                                        // style={buttonStyles}
                                        type='button'
                                    >
                                        80€
                                    </button>
                                    <button
                                        className={`t-810-block1-button${(amount === 150) ? ' active' : ''} ${deviceType}`}
                                        // disabled={isLoading}
                                        onClick={() => handleAmountChange(150)}
                                        // onMouseLeave={() => onHover('leave')}
                                        // onMouseOver={() => onHover('over')}
                                        // style={buttonStyles}
                                        type='button'
                                    >
                                        150€
                                    </button>
                                    <button
                                        className={`t-810-block1-button${(amount === 200) ? ' active' : ''} ${deviceType}`}
                                        // disabled={isLoading}
                                        onClick={() => handleAmountChange(200)}
                                        // onMouseLeave={() => onHover('leave')}
                                        // onMouseOver={() => onHover('over')}
                                        // style={buttonStyles}
                                        type='button'
                                    >
                                        200€
                                    </button>
                                </div>
                                <div className={`t-810-block1-custom-amount ${deviceType}`}>
                                    <span className={`t-810-label ${deviceType}`}>Autre montant</span>
                                    <div className={`t-810-block1-input-wrapper ${deviceType}`}>
                                        <input
                                            className={`t-810-amount ${deviceType}`}
                                            min={0}
                                            name='amount'
                                            onChange={(e) => handleAmountChange(e.target.value)}
                                            step='5'
                                            tabIndex={1}
                                            type='number'
                                            value={amount}
                                        />
                                        <span className='t-810-currency-symbol'>{reduxCacheSiteportfoliocurrencyiso_code}</span>
                                    </div>
                                    {amountError && (
                                        <span className='t-810-error'>{amountError}</span>
                                    )}
                                </div>
                            </div>
                            <div className={`t-810-block2 ${deviceType}`}>
                                <span className={`t-810-title ${deviceType}`}>Mes coordonées</span>
                                <span className={`t-810-label ${deviceType}`}>{reduxText[8503]}</span>
                                <input
                                    className={`t-810-email ${deviceType}`}
                                    name='email'
                                    onChange={handleEmailChange}
                                    tabIndex={3}
                                    type='email'
                                    value={email}
                                />
                                {emailError && (
                                    <span className='t-810-error'>{emailError}</span>
                                )}
                                <Elements stripe={reduxModalSite.stripePromise}>
                                    <ButtonHelper810Site
                                        amount={amount}
                                        buttonStyles={buttonStyles}
                                        buttonText={content?.button_text}
                                        email={email}
                                        isRecurent={isRecurent}
                                        onHover={onHover}
                                        productId={content?.stripe_product_id}
                                        setAmountError={setAmountError}
                                        setEmailError={setEmailError}
                                    />
                                </Elements>
                                <span className={`t-810-secure ${deviceType}`}>{reduxText[8504]}</span>
                            </div>
                        </React.Fragment>
                    )
                }
                <EditBlockSite
                    isInComponent={isInComponent}
                    mainParentId={mainParentId || object.id!}
                    object={object}
                    parentArray={parentArray}
                    parentStyles={parentStyles}
                />
            </div>
        )
    }

    return (
        <React.Fragment>
            <div
                id={blockId}
                className={`template-block-810${isEditHovered ? ' is-edit-hovered' : ''} ${stylesNew?.colorMode || 'dark'} ${deviceType}`}
            >
                {parseQuerySite(location.search).success
                    ? (
                        <p className={`t-810-sucess-message ${deviceType}`}>{content?.success_message || reduxText[8519]}</p>
                    ) : (
                        <React.Fragment>
                            <span className={`t-810-label ${deviceType}`}>{reduxText[8502]}</span>
                            <input
                                className={`t-810-amount ${deviceType}`}
                                min={0}
                                name='amount'
                                onChange={(e) => handleAmountChange(e.target.value)}
                                step='5'
                                tabIndex={1}
                                type='number'
                                value={amount}
                            />
                            <span className={`t-810-currency ${deviceType}`}>{reduxCacheSiteportfoliocurrencyiso_code}</span>
                            {amountError && (
                                <span className='t-810-error'>{amountError}</span>
                            )}
                            <div
                                className={`t-810-monthly-wrap ${deviceType}`}
                                onClick={() => setIsRecurent(!isRecurent)}
                            >
                                <input
                                    checked={isRecurent}
                                    className={`t-810-monthly ${deviceType}`}
                                    name='isRecurent'
                                    tabIndex={2}
                                    type='checkbox'
                                />
                                {reduxText[8501]}
                            </div>
                            <span className={`t-810-label ${deviceType}`}>{reduxText[8503]}</span>
                            <input
                                className={`t-810-email ${deviceType}`}
                                name='email'
                                onChange={handleEmailChange}
                                tabIndex={3}
                                type='email'
                                value={email}
                            />
                            {emailError && (
                                <span className='t-810-error'>{emailError}</span>
                            )}
                            <Elements stripe={reduxModalSite.stripePromise}>
                                <ButtonHelper810Site
                                    amount={amount}
                                    buttonStyles={buttonStyles}
                                    buttonText={content?.button_text}
                                    email={email}
                                    isRecurent={isRecurent}
                                    onHover={onHover}
                                    productId={content?.stripe_product_id}
                                    setAmountError={setAmountError}
                                    setEmailError={setEmailError}
                                />
                            </Elements>
                            <span className={`t-810-secure ${deviceType}`}>{reduxText[8504]}</span>
                        </React.Fragment>
                    )
                }
                <EditBlockSite
                    isInComponent={isInComponent}
                    mainParentId={mainParentId || object.id!}
                    object={object}
                    parentArray={parentArray}
                    parentStyles={parentStyles}
                />
            </div>
        </React.Fragment>
    )
})
